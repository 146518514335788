import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Player } from '../../models/player.model';

@Component({
  selector: 'app-player-sheet',
  templateUrl: './player-sheet.component.html',
  styleUrls: ['./player-sheet.component.css'],
})
export class ScoreCardComponent implements OnInit {
  constructor() {}

  @Input() player!: Player;
  @Output() playerChange = new EventEmitter<Player>();

  @Input() isDeleteDisabled!: boolean;
  @Output() onPlayerDeletion = new EventEmitter<Player>();
  @Output() onPearlMasterSet = new EventEmitter<Player>();

  delete() {
    this.onPlayerDeletion.emit(this.player);
  }
  setPearlMaster(change: MatCheckboxChange) {
    if (change.checked) this.onPearlMasterSet.emit(this.player);
  }

  ngOnInit(): void {}

  trackByFn(index: number, element: any) {
    return index;
  }
}
