<div class="player-sheet-container">
  <mat-card class="player-header">
    <mat-form-field class="name-input" appearance="standard">
      <mat-label>Nom</mat-label>
      <input
        onClick="this.select()"
        tabindex="1"
        matInput
        [id]="'name_' + player.id"
        [(ngModel)]="player.name"
      />
    </mat-form-field>

    <!-- Player controls -->
    <div id="player-controls">
      <!-- Delete player -->
      <button mat-icon-button (click)="delete()" [disabled]="isDeleteDisabled">
        <mat-icon>person_remove</mat-icon>
      </button>

      <!-- Reset player score -->
      <button mat-icon-button (click)="player.resetScore()">
        <mat-icon>restart_alt</mat-icon>
      </button>
    </div>
  </mat-card>

  <!-- Select card value -->
  <app-faction-card-selector
    class="faction-selector"
    *ngFor="let faction of player.board.factions; let index = index; trackBy: trackByFn"
    [(faction)]="player.board.factions[index]"
  ></app-faction-card-selector>

  <!-- Select coalition -->
  <div class="px-1 mt-1">
    <p class="my-0 flex-spaced-between">
      <span>Coalition :</span>
      <span>
        {{ player.board.coalition }} 🃏 ➡ {{ player.board.coalition * 3 }} 🔱
      </span>
    </p>
    <mat-slider
      min="0"
      max="12"
      step="1"
      [(ngModel)]="player.board.coalition"
      thumbLabel
    ></mat-slider>
  </div>

  <!-- Select places -->
  <div class="px-1">
    <p class="my-0 flex-spaced-between">
      <span>Lieux :</span><span>{{ player.board.places }} 🔱</span>
    </p>
    <mat-slider
      min="0"
      max="42"
      step="1"
      [(ngModel)]="player.board.places"
      thumbLabel
    ></mat-slider>
  </div>

  <mat-checkbox
    class="px-1"
    [(ngModel)]="player.board.isPearlMaster"
    (change)="setPearlMaster($event)"
  >
    Maître des perles 🦪
    <span [hidden]="!player.board.isPearlMaster"> +5🔱</span>
  </mat-checkbox>

  <!-- Display player score -->
  <mat-card class="flex-spaced-between px-2">
    <span>Score :</span><span>{{ player.score }} 🔱</span>
  </mat-card>
</div>
