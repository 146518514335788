import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatorComponent } from './calculator.component';
import { MaterialModule } from '../material/material.module';
import { ScoreCardComponent } from './components/player-sheet/player-sheet.component';
import { FactionCardSelectorComponent } from './components/faction-card-selector/faction-card-selector.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CalculatorComponent,
    ScoreCardComponent,
    FactionCardSelectorComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule],
  exports: [CalculatorComponent],
})
export class CalculatorModule {}
