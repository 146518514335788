<div class="calculator-container">
  <!-- Controls -->
  <mat-card class="flex-center">
    <button mat-button [disabled]="isAddDisabled" (click)="addPlayer()">
      <mat-icon>person_add</mat-icon> Ajouter un joueur
    </button>
    <button mat-button (click)="resetAllScores()">
      <mat-icon>restart_alt</mat-icon> Nouvelle partie
    </button>
  </mat-card>

  <!-- Player list -->
  <div class="sheets">
    <app-player-sheet
      *ngFor="let player of players; let index = index; trackBy: trackByPlayer"
      [(player)]="players[index]"
      [isDeleteDisabled]="isDeleteDisabled"
      (onPlayerDeletion)="deletePlayer($event)"
      (onPearlMasterSet)="claimPearlMaster($event)"
    ></app-player-sheet>
  </div>

  <!-- Score  -->
  <div class="flex-center" *ngIf="winner">
    <p>👑🧜‍♂️ {{ winner.name }} a gagné avec {{ winner.score }} points 🔱 !</p>
  </div>

  <mat-card class="flex-col-center">
    <p *ngFor="let player of playersByScore">
      {{ player.name }} a {{ player.score }} points 🔱
    </p>
  </mat-card>
</div>
