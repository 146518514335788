export enum Card {
  None = 0,
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Six = 6,
}

export enum Color {
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Yellow = 'yellow',
  Purple = 'purple',
}

export class Faction {
  highest: Card = Card.None;
  constructor(public color: Color) {}
}

export class Board {
  factions: Faction[] = [
    new Faction(Color.Red),
    new Faction(Color.Blue),
    new Faction(Color.Green),
    new Faction(Color.Yellow),
    new Faction(Color.Purple),
  ];
  coalition: number = 0;
  places: number = 0;
  isPearlMaster: boolean = false;

  factionScore() {
    return this.factions.reduce(
      (sum, faction) => (sum + faction.highest) as number,
      0
    );
  }
}

const DEFAULT_PLAYER_NAME = 'Joueur';

export class Player {
  constructor(public id: number) {
    this.name = `${DEFAULT_PLAYER_NAME} ${id + 1}`;
  }

  name: string;
  board: Board = new Board();

  resetScore() {
    this.board = new Board();
  }

  /**
   * Calculate the score of the player
   */
  get score() {
    return (
      this.board.factionScore() +
      this.board.places +
      this.board.coalition * 3 +
      (this.board.isPearlMaster ? 5 : 0)
    );
  }
}
