import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Card, Faction } from '../../models/player.model';

/**
 * Choices displayed on the selector
 */
const DEFAULT_CHOICES = [
  Card.Zero,
  Card.One,
  Card.Two,
  Card.Three,
  Card.Four,
  Card.Six,
];

@Component({
  selector: 'app-faction-card-selector',
  templateUrl: './faction-card-selector.component.html',
  styleUrls: ['./faction-card-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FactionCardSelectorComponent implements OnInit {
  constructor() {}

  choices: Card[] = DEFAULT_CHOICES;

  @Input() faction!: Faction;
  @Output() factionChange = new EventEmitter<Faction>();

  select(card: Card) {
    this.faction.highest = card;
    this.factionChange.emit(this.faction);
  }

  ngOnInit(): void {}

  trackByFn(index: number, card: Card) {
    return index;
  }
}
