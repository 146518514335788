import { Component, OnInit } from '@angular/core';
import { Player } from './models/player.model';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css'],
})
export class CalculatorComponent implements OnInit {
  players: Player[] = [];
  nextPlayerId: number = 0;

  constructor() {
    this.addPlayer();
    this.addPlayer();
  }

  addPlayer() {
    if (this.players.length >= 4) return;
    this.players = [...this.players, new Player(this.nextPlayerId++)];
  }

  deletePlayer(deletedPlayer: Player) {
    if (this.players.length <= 2) return;
    const index = this.players.findIndex((player) => player === deletedPlayer);
    this.players.splice(index, 1);
  }

  trackByPlayer(index: number, player: Player) {
    return player.id;
  }

  resetAllScores() {
    this.players = this.players.map((player) => {
      player.resetScore();
      return player;
    });
  }

  claimPearlMaster(newPearlMaster: Player) {
    this.players = this.players.map((player) => {
      if (player !== newPearlMaster) player.board.isPearlMaster = false;
      return player;
    });
  }

  get isDeleteDisabled() {
    return this.players.length <= 2;
  }

  get isAddDisabled() {
    return this.players.length >= 4;
  }

  get playersByScore() {
    const sorted = [...this.players].sort((a, b) => b.score - a.score);
    return sorted;
  }

  get winner() {
    const highest = this.playersByScore[0];
    if (highest?.score > 0) return highest;
    return;
  }

  ngOnInit(): void {}
}
