<mat-button-toggle-group
  class="faction-group"
  [ngClass]="faction.color"
  [(ngModel)]="faction.highest"
>
  <mat-button-toggle
    class="faction-button"
    *ngFor="let card of choices; trackBy: trackByFn"
    [value]="card"
  >
    {{ card }}
  </mat-button-toggle>
</mat-button-toggle-group>
