<div id="page">
  <!-- Menu -->
  <mat-toolbar color="primary">
    <div>{{ title }}</div>
    <img
      style="padding-left: 1rem"
      src="assets/icons/hermit-crab-48x48.png"
      width="48px"
      height="48px"
    /><img />
  </mat-toolbar>

  <!-- Modules -->
  <div id="content">
    <app-calculator></app-calculator>
  </div>

  <app-footer></app-footer>
</div>
